import {http} from './config';

export default {
    create:(type) => {
        return http.post('api/v1/formas-pagamentos', type);
    },
    index:(page=1) => {
        return http.get('api/v1/formas-pagamentos?page=' + page);
    },
    update:(type) => {
        return http.put('api/v1/formas-pagamentos/' + type.id, type);
    },
    delete: (id) => {
        return http.delete('api/v1/formas-pagamentos/' + id);
    },
    list: () => {
        return http.get('api/v1/formas-pagamentos/listar');
    }
}