export default Object.freeze({
    entrance: 1,
    arras: 2,
    intermediate: 3,
    brokerage: 4,
    regular: 5,

    labelEntrance: 'Entrada',
    labelArras: 'Arras',
    labelIntermediate: 'Intermediárias',
    labelBrokerage: 'Corretagem',
    labelRegular: 'Parcelas Regulares',

    defaultColorEntrance: '#ffffed',
    defaultColorArras: '#ffc2c2',
    defaultColorIntermediate: '#d9f9ff',
    defaultColorBrokerage: '#e6d6f6',
    defaultColorRegular: '#fff3cf',

    defaultFormula: 'PRICE',
    formulas: ['SAC', 'PRICE', 'SACOC - S', 'SACOC - C'],
});